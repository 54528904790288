<template>
  <div class="homeContainer">
    <div class="topBanner">
      <!-- <img src="../assets/am-top-bg.png" class="top-line-logo" /> -->
      <!-- <div class="topNavTitle">{{project_name}} </div> -->

      <div class="infoView">

        <img class="studentlogo" src="../assets/am-logo.png"/>
        <div class="studentNameStyle" @click="changeView('')">
          {{ student_name }}
        </div>
        <div class="logoutStyle" @click="changeView('')">
          Log out
        </div>
      </div>

    </div>
    <div class="main">

      <div class="mainLeft">
        <div class="topMenu">

          <span class="menuStyle"> MY MENU</span>
          <div v-if="user_type === 'student'" class="mainLeftContent">
            <div
                v-for="item in nav"
                :key="item.name"
                class="navOutBox"
                @click="changeView(item)"
            >
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>


              <div
                  :class="{ navInBox: true, selected: currentNav === item.router }"
              >
                {{ item.name }}
              </div>
            </div>
            <!-- <div class="navOutBox" @click="changeView('')">退出登录</div> -->
          </div>
          <div v-if="user_type === 'educator'" class="mainLeftContent">
            <div
                v-for="item in navContact"
                :key="item.name"
                class="navOutBox"
                @click="changeView(item)"
            >
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>

              <div
                  :class="{ navInBox: true, selected: currentNav === item.router }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div v-if="user_type === 'employee'" class="mainLeftContent">
            <div
                v-for="item in navEmployee"
                :key="item.name"
                class="navOutBox"
                @click="changeView(item)"
            >
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>

              <div
                  :class="{ navInBox: true, selected: currentNav === item.router }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="mainRight">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {
  logout,
  getUserType,
  getUserName,
  getAccountName,
  getAvatarUrl,
  getProjectName, getProjectCode
} from "../utils/store";
import {
  getProjectInfo
} from '../api/index'

export default {
  data() {
    return {
      nav: [
        {
          name: "Notices & Information",
          selected: require("../assets/nav/peixun1.png"),
          unselected: require("../assets/nav/peixun.png"),
          router: "/announcements",
        },
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/studentlist",
        },
        // {
        //   name: "Rankings",
        //   selected: require("../assets/nav/chengji1.png"),
        //   unselected: require("../assets/nav/chengji.png"),
        //   router: "/score",
        // },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        {
          name: "Contact Us",
          selected: require("../assets/nav/xiaoxi1.png"),
          unselected: require("../assets/nav/xiaoxi.png"),
          router: "/message",
        },
        //奖项
        // {name:'消息社交',selected:require('../assets/nav/award1.png'),unselected:require('../assets/nav/award.png'),router:'/award'},//奖项
        // {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      navContact: [
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/teacherlist",
        },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      navEmployee: [
        // {
        //   name: "赛前培训",
        //   selected: require("../assets/nav/peixun1.png"),
        //   unselected: require("../assets/nav/peixun.png"),
        //   router: "/peixunadmin",
        // },
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/employeelist",
        },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        // {
        //   name: "监考",
        //   selected: require("../assets/nav/bisai1.png"),
        //   unselected: require("../assets/nav/bisai.png"),
        //   router: "/examadminlist",
        // },
        // // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      user_type: getUserType(),
      project_name: getProjectName(),
      currentNav: "/peixun",
      student_name: "",
      student_school: "",
      application_project: "",
      avatar_url: "",
    };
  },
  watch: {
    $route(to, from) {
      this.currentNav = this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
    this.student_name = getUserName();
    this.student_school = getAccountName();
    this.avatar_url = getAvatarUrl();
    this.currentNav = this.$route.path;
    this.requestProjectConfig()
  },
  methods: {

    requestProjectConfig() {
      getProjectInfo(getProjectCode()).then((res) => {
        let project = res.data
        if (project.project_exam_system_results_model_show && project.project_exam_system_results_model_show == 'show') {
          this.nav.push(
              {
                name: "Results",
                selected: require("../assets/nav/award1.png"),
                unselected: require("../assets/nav/award.png"),
                router: "/newaward",
              },
          )
        }
      })
    },

    logout() {
      logout();
      location.reload();
    },
    changeView(item) {
      if (item.router) {
        this.currentNav = item.router;
        this.$router.push(item.router);
      } else {
        this.$confirm("Are you sure you want to exit?", "Tips", {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          type: "warning",
        }).then(() => {
          this.logout();
        });
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.content-logout {
  box-sizing: border-box;
  width: 380px;
  position: absolute;
  bottom: 0;
  padding-left: 70px;
  height: 10vh;
  background-color: #FFFAF4;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vh;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);

  .iconImg {
    height: 3vh;
    margin-right: 6px;
  }
}

.menuStyle {
  color: #C5C9DE;
  align-content: center;
  text-align: left;
  margin-top: 20px;
  margin-left: 50px;
  font-size: 15px;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .topBanner {
    position: relative;
    flex-shrink: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    // align-items: center;
    // justify-content: center;
    height: 120px;
    background-image: url("../assets/newTopBg.png");
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    // background: #00348b;
    min-width: 1100px;

    .top-line-logo {
      height: 24px;
    }

    .topNavTitle {
      position: absolute;
      margin-left: 16%;
      margin-top: 30px;
      text-align: left;
      align-items: left;
      margin-right: 50px;
      color: white;
      font-size: 40px;
      // justify-content: left;
      // background-color: red;

    }

    .infoView {
      position: absolute;
      margin-left: 0;
      width: 15%;
      height: 90px;
      margin-top: 30px;
      background-color: #F3F5F9;
      border-top-right-radius: 10px;
    }

    .logoutStyle {
      // position: absolute;
      margin-left: 40px;
      // margin-right: 20px;
      height: 20px;
      width: calc(100% - 60px);
      margin-top: 20px;
      border: 1px solid #B2B6C5;
      // border-radius: 3px;
      color: #B2B6C5;
      text-align: center;
      line-height: 20px;
    }

    .studentNameStyle {
      margin-left: 70px;
      margin-top: 20px;
      font-size: 13px;
      color: #1B213D;
      text-align: left;
    }

    .studentlogo {
      position: absolute;
      margin-top: 20px;
      margin-left: 40px;
      width: 22px;
      height: 20px;
      // background-color: red;
      // border-radius: 10px;
    }

  }

  .main {
    padding: 0;
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;

    .topMenu {
      flex-direction: column;
      display: flex;
      width: 100%;
      height: 100%;
      background-color: #FBFCFD;

      .mainLeftContent {
        // display: flex;
        // .navOutBox {
        //   cursor: pointer;
        //   font-weight: bold;
        //   color: #878FB1;
        //   margin-left: 70px;
        //   .navInBox {
        //     height: 28px;
        //     color: #1B213D;
        //     display: flex;
        //     font-family: Microsoft YaHei;
        //     font-weight: bold;
        //   }

        //   .selected {
        //     border-bottom: 2px solid #1B213D;
        //   }
        // }
        .navOutBox {
          width: 100%;
          height: 10vh;
          background: #FBFCFD;
          display: flex;
          flex-direction: row;
          cursor: pointer;

          .navInBox {
            width: 380px;
            padding-left: 10px;
            height: 10vh;
            align-items: center;
            justify-content: flex-start;
            background: #FBFCFD;
            display: flex;
            font-size: 2vh;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #6284C6;


          }

          .iconImg {
            padding-left: 20px;
            left: 20px;
            margin-left: 20px;
            margin-top: calc((10vh - 30px) / 2);
            align-items: center;
            // background-color: rebeccapurple;
            height: 30px;
            width: 30px;
            // margin-right: 6px;
          }

          .selected {
            background-color: #FBFCFD;
            color: #072D76;
          }
        }
      }
    }

    .mainBack {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .mainLeft {
      width: 15%;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #FBFCFD;
    }

    .mainRight {
      // background-image: url("../assets/magin_right_bg.png");
      // background-repeat: no-repeat;
      background-color: #F3F5F9;
      background-size: cover;
      width: 85%;
      height: 100%;
      overflow-y: scroll;
    }
  }
}
</style>
